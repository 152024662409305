body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  padding-right: 0 !important;
  overflow: visible !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#root {
  z-index: 10;
  position: relative;
  min-height: 100vh;
}
* {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  transition: all 0.1s ease-in-out 0s;
}
